import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './AppRoutes';

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<Router>
					{/* <header className="header">
						<img src="logo.png" alt='logo' className='logoSize' ></img>
					</header> */}
					<div className='bodyWrapper'>

						<AppRoutes/>
					</div>
				</Router>
			</AuthProvider>
		</QueryClientProvider>
	);
}

export default App;
