/* eslint-disable @typescript-eslint/no-explicit-any */
import { Coordinate } from './components/tool/types';

function debounce(func: (...args: any[]) => void, timeout = 50) {
	let debouncedFunc: NodeJS.Timeout | null = null;

	return function (this: ThisParameterType<typeof func>, ...args: any[]) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (!debouncedFunc) {
			debouncedFunc = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		} else {
			clearTimeout(debouncedFunc);
			debouncedFunc = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		}
	};
}

function mapNumber(mapNum: number, inMin: number, inMax: number, outMin: number, outMax: number) {
	return Math.round((mapNum - inMin) * (outMax - outMin) / (inMax - inMin) + outMin);
}

function createApiUrl(url: string) {
	console.log('eennv', process.env.NODE_ENV, process.env.REACT_APP_LOCAL_API_URL);
	if (process.env.NODE_ENV === 'production') {
		return `${process.env.REACT_APP_PRODUCTION_API_URL}${url}`;
	}
	return `${process.env.REACT_APP_LOCAL_API_URL}${url}`;
}

function numberInRange(num: number, min: number, max: number): boolean {
	return num >= min && num <= max;
}

function makePanelKeyFromCoordinate(coord: Coordinate) {
	return `${coord.latitude},${coord.longitude}`;
}

function getToken() {
	const token = localStorage.getItem('ROOFQUOTEEMBED_AUTHORIZATION');
	return token;
}

const pitchDegMap = [
	[0, 1.99, '0/12'],
	[2, 6.749, '1/12'],
	[6.75, 11.749, '2/12'],
	[11.75, 16.249, '3/12'],
	[16.25, 20.49, '4/12'],
	[20.5, 24.49, '5/12'],
	[24.5, 28.49, '6/12'],
	[28.5, 32.1249, '7/12'],
	[32.125, 35.3749, '8/12'],
	[35.375, 38.49, '9/12'],
	[38.5, 41.249, '10/12'],
	[41.25, 43.749, '11/12'],
	[43.75, 46.1449, '12/12'],
	[46.145, 48.3449, '13/12'],
	[48.345, 50.369, '14/12'],
	[50.37, 52.369, '15/12'],
	[52.235, 53.955, '16/12'],
	[53.955,	55.545, '17/12'],
	[55.545,	57.015, '18/12'],
	[57.015,	58.38, '19/12'],
	[58.38,	59.65, '20/12'],
	[59.65,	60.825, '21/12'],
	[60.825,	61.92, '22/12'],
	[61.92,	62.94, '23/12'],
	[62.94,	64.4, '24/12'],
];

function getPitchVal(degree: string) {
	for (let i = 0; i < pitchDegMap.length; i++) {
		const degreeInBetween = degree >= pitchDegMap[i][0] && degree <= pitchDegMap[i][1];
		if (degreeInBetween) {
			return String(pitchDegMap[i][2]);
		}
	}
	return undefined;
}

export {
	debounce,
	mapNumber,
	createApiUrl,
	numberInRange,
	makePanelKeyFromCoordinate,
	getToken,
	getPitchVal
};
