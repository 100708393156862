import { useQuery } from 'react-query';
import { useAuth, useAuthDispatch } from '../contexts/AuthContext';
import { getToken } from '../util';
import { verifyToken } from '../pages/Organisation/api';
import Loader from '../components/Loader';
import Unauthorized from '../components/Unauthorized';

type Props = {
	children: JSX.Element
}

export default function PrivateRoute(props: Props) {
	const { token } = useAuth();
	const dispatch = useAuthDispatch();
	// console.log('tokenQuery',token);
	const tokenQuery = useQuery('myToken', async () => {
		// use local storage instead
		const myToken = getToken();
		if(!myToken) return false;
		const data = await verifyToken(myToken);
		data && dispatch({type: 'SET', payload:{ token: myToken}});
		return data;
	}, {cacheTime:0, enabled: false,refetchOnMount:false});
	// console.log(tokenQuery,token);

	if (!token) {
		tokenQuery.refetch();
	} else if(token && tokenQuery.isIdle) {
		return props.children;
	}

	if(tokenQuery.isLoading) {
		return <Loader/>;
	}

	if(!tokenQuery.data) {
		return <Unauthorized/>;
	}

	if(tokenQuery.data) {
		return props.children;
	}

	return null;

}