import axios from 'axios';
import { createApiUrl } from '../../util';

async function generateOrganization(name:string) {
	const requestBody = {
		name
	};
	const { data } = await axios.post(createApiUrl('/organization'), requestBody);
	return data;
}

async function verifyToken(token:string) {
	const { data } = await axios.get(createApiUrl(`/organization/verify/${token}`));
	return data;
}

export { 
	generateOrganization,
	verifyToken
};