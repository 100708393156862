import loader from './loader.gif';
import { LoaderDiv } from './styles';

type Props = {
	loaderText?: string;
}
export default function Loader(props: Props) {
	const { loaderText } = props;
	return <>
		<LoaderDiv>
			<img src={loader} />
			{
				loaderText &&
				<>{loaderText}...</>
			}
		</LoaderDiv>
	</>;
}