import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import Loader from '../components/Loader';
import ROUTES from '../constants/routes';
import PrivateRoute from './PrivateRoute';
const LazyLoadRoofDesign = React.lazy(() => import('../pages/LoadRoofDesign/LoadRoofDesign'));
const LazyLocationMap = React.lazy(() => import('../pages/LocationMap/LocationMap'));
const LazyHome = React.lazy(() => import('../pages/Home'));

// and seperate provate routes from public routes

// wrap private compoents like and check if you need to pass props too
{
	/* <PRivate></PRivate> */
}
export default function AppRoutes() {
	return useRoutes([
		{
			path: ROUTES.HOME,
			element:
				<React.Suspense fallback={<Loader />}>
					<LazyHome />
				</React.Suspense>
		},
		{
			path: ROUTES.SHOW_MAP,
			element: (
				<React.Suspense fallback={<Loader />}>
					<PrivateRoute>
						<LazyLocationMap />
					</PrivateRoute>
				</React.Suspense>
			),
		},
		{
			path: ROUTES.SHOW_DATA,
			element: (
				<React.Suspense fallback={<Loader />}>
					<PrivateRoute>
						<LazyLoadRoofDesign />
					</PrivateRoute>
				</React.Suspense>
			),
		},
	]);
}
