import { createContext, useContext, useReducer, ReactNode, useMemo } from 'react';

type AuthState = {
	token: string | undefined;
}

type AuthPayload = {
	token: string | undefined;
}

type ActionType = 'SET' | 'DELETE';

type Action = { type: ActionType, payload: AuthPayload };

export type Dispatch = (action: Action) => void;

function authReducer(authState: AuthState, action: Action): AuthState {
	const { token } = action.payload;
	switch (action.type) {
	case 'SET': {
		return {
			...authState,
			token,
		};
	}
	case 'DELETE': {
		return {
			...authState,
			token,
		};
	}
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}

const AuthContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

export function AuthProvider(props: { children: ReactNode }) {
	const { children } = props;
	const [state, dispatchFunc] = useReducer(authReducer, { token: undefined });

	const toolState = useMemo(() => state, [state]);
	const dispatch = useMemo(() => dispatchFunc, [dispatchFunc]);

	return (
		<AuthContext.Provider value={toolState} >
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within a ToolProvider');
	}
	return context;
}

export function useAuthDispatch() {
	const context = useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a ToolProvider');
	}
	return context;
}

AuthContext.displayName = 'EnergyContext';