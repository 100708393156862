import styled from 'styled-components';

const LoaderDiv = styled.div`
display: flex;
flex-direction:column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;	
min-height: 100vh;
img{margin-top: 5px;}
`;

export {
	LoaderDiv,
};